import { ButtonComponent } from '@/ux/components/button/button.component';
import { FormControlComponent } from '@/ux/components/form-control/form-control.component';
import { markForm } from '@/ux/utils/form.utils';
import { Component } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../core/services/auth.service';
import { BgAuthComponent } from '@/auth/components/bg-auth/bg-auth.component';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ButtonComponent,
    FormControlComponent,
    FormsModule,
    ReactiveFormsModule,
    BgAuthComponent,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  host: { class: 'page' },
})
export class LoginComponent {
  form: UntypedFormGroup;

  constructor(
    private auth: AuthService,
    private router: Router,
  ) {
    if (this.auth.authenticated) this.router.navigateByUrl('');
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', [Validators.required]),
    });
  }

  login() {
    this.auth
      .login(this.form.value)
      .pipe(markForm(this.form))
      .subscribe(() => this.router.navigateByUrl('/operations/mytickets'));
  }
}
