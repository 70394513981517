@if (user$ | async; as user) {
  <div class="profile-info animate-fade-right">
    <div class="avatar flex self-center p-6">
      <div class="w-64 rounded-full shadow-lg animate-fade-down">
        <img
          [src]="user?.image"
          onerror="this.src='/assets/images/default-profile.jpg';this.onerror=null;"
        />
      </div>
    </div>
    <div class="divider"></div>
    <div class="profile-info__details animate-fade-left">
      <h3>Información del usuario</h3>
      <div class="profile-info__items">
        <div class="item">
          <i class="ri-account-circle-line"></i>
          <span>{{ user?.fullname }}</span>
        </div>
        <div class="item">
          <i class="ri-mail-line"></i>
          <span>{{ user?.email }}</span>
        </div>
        <div class="item">
          <i class="ri-phone-line"></i>
          <span>{{ user?.phone || "No disponible" }}</span>
        </div>
        <div class="item">
          <i class="ri-profile-line"></i>
          <span>{{ user?.dni || "No disponible" }}</span>
        </div>
        <div class="item">
          <span class="rol">Rol</span>
          <span>{{ user?.role }}</span>
        </div>
      </div>

      <div class="profile-info__actions">
        <button class="btn" (click)="edit()">
          Actualizar Datos <i class="ri-pencil-line"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="boxes">
    <div class="boxes__passwords">
      <h3>Cambiar contraseña</h3>
      <div class="password-form">
        <app-password-form></app-password-form>
      </div>
    </div>

    <div class="boxes__notifications">
      <h3>Notificaciones</h3>
      <app-notifications-config [configs]="user.configs"></app-notifications-config>
    </div>
  </div>
}
