import { Component } from '@angular/core';

@Component({
  selector: 'app-bg-auth',
  standalone: true,
  templateUrl: './bg-auth.component.html',
  styleUrls: ['./bg-auth.component.scss'],
})
export class BgAuthComponent {
  constructor() {}
}
