import { Component, Input, ViewChild, inject } from '@angular/core';
import { DialogRef } from '@/ux/services/dialog';
import { RuleControl } from '../../objects/rule/rule.types';
import { ResourcesService } from '../../services/resources.service';
import { ResourceFormComponent } from '../resource-form/resource-form.component';

@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.scss']
})
export class FormDialogComponent {
  service = inject(ResourcesService);
  ref?: DialogRef = inject(DialogRef, { optional: true });

  @ViewChild(ResourceFormComponent, { static: true })
  form: ResourceFormComponent;

  @Input()
  description: string;

  @Input()
  title: string;

  @Input()
  data: any;

  @Input()
  controls: RuleControl[] = [];

  submit() {
    this.form.getSubmitData().subscribe((r) => this.ref?.close(r));
  }
}
