import { AuthService } from '@/core/services/auth.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-notifications-config',
  standalone: true,
  templateUrl: './notifications-config.component.html',
  styleUrls: ['./notifications-config.component.scss'],
})
export class NotificationsConfigComponent implements OnInit {
  @Input() configs: any = {};
  assignment: boolean;
  mention: boolean;
  newComment: boolean;
  ticketModified: boolean;

  constructor(private auth: AuthService) {}

  ngOnInit() {
    this.assignment = this.configs.assignment !== undefined ? this.configs.assignment : true;
    this.mention = this.configs.mention !== undefined ? this.configs.mention : true;
    this.newComment = this.configs.new_comment !== undefined ? this.configs.new_comment : true;
    this.ticketModified =
      this.configs.ticket_modified !== undefined ? this.configs.ticket_modified : true;
  }

  update() {
    const configs = {
      assignment: this.assignment,
      mention: this.mention,
      new_comment: this.newComment,
      ticket_modified: this.ticketModified,
    };

    this.auth.updateme({ configs }).subscribe();
  }

  onChange(event: Event, configType: string) {
    const input = event.target as HTMLInputElement;
    this[configType] = input.checked;
    this.update();
  }
}
