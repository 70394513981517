import { AkilesService } from '@/admin/services/akiles.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { Dialog } from '@angular/cdk/dialog';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { ConfigService } from '@core/services/config.service';
import { DialogRef, DialogService } from '@/ux/services/dialog';
import { format } from 'date-fns';
import { Observable, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { RuleControl } from 'src/app/legacy/objects/rule/rule.types';
import { ResourcesService } from 'src/app/legacy/services/resources.service';
import { TicketFormComponent } from 'src/app/operations/components/ticket-form/ticket-form.component';
import { TicketsService } from 'src/app/operations/services/tickets.service';
import { DropdownComponent } from 'src/app/ux/components/dropdown/dropdown.component';
import { UXService } from 'src/app/ux/services/ux.service';
import { FormDialogComponent } from '../../../legacy/components/form-dialog/form-dialog.component';
import { GateService } from '../../services/gate.service';
import { TicketViewComponent } from '../ticket-view/ticket-view.component';
import { Integration } from '@/admin/models/integration';
import { TicketsListComponent } from '../tickets-list/tickets-list.component';

@Component({
  selector: 'app-ticket-bar',
  templateUrl: './ticket-bar.component.html',
  styleUrls: ['./ticket-bar.component.scss'],
})
export class TicketBarComponent {
  #gate = inject(GateService);
  configs = inject(ConfigService);
  resources = inject(ResourcesService);
  auth = inject(AuthService);
  service = inject(TicketsService);
  akilesService = inject(AkilesService);
  ux = inject(UXService);
  dialog = inject(DialogService);
  #dialog = inject(Dialog);
  http = inject(HttpClient);
  clipboard = inject(Clipboard);
  ref?: DialogRef = inject(DialogRef, { optional: true });
  loadingCopy = signal(false);
  loadingOpen = signal(false);

  @ViewChild('ticketTemplate', { read: TemplateRef })
  ticketTemplate!: TemplateRef<any>;

  @ViewChild('statusMenu', { read: DropdownComponent })
  statusMenu!: DropdownComponent;

  @Output() updated: EventEmitter<void> = new EventEmitter();
  @Input() ticket?: any;
  @Input() around?: any;
  @Input() isLoading?: boolean;
  @Input() readonly?: boolean = false;

  observationControls: any;
  users$: Observable<any>;
  akiles = false;

  constructor() {
    const userToOption = (u: any) => ({
      label: `${u.name} ${u.surname || ''}`.trim(),
      value: u.id,
    });
    this.users$ = this.configs.get('users').pipe(map((users: any[]) => users.map(userToOption)));

    this.configs.get('integrations').subscribe((integrations: Integration[]) => {
      this.akiles = integrations.some((i: Integration) => i.provider === 'akiles');
    });

    this.observationControls = [
      new RuleControl('related_files', 'Files', this.resources, {
        type: 'file',
        accept: '*',
      }),
      new RuleControl('text', 'Description', this.resources, {
        type: 'textarea',
      }),
    ];
  }

  start() {
    const endpoint = `//api/tickets/${this.ticket?.id}/start`;
    this.http.put(endpoint, { force_start: true }).subscribe(() => this.updated.emit());
  }

  finish(status: 'canceled' | 'resolved' = 'resolved') {
    const endpoint = `//api/tickets/${this.ticket.id}/finish`;

    this.ux
      .confirmUpdateOrDelete()
      .pipe(
        filter((confirm) => !!confirm),
        mergeMap(() => {
          if (this.ticket.started_at && status === 'canceled') {
            const inputs = {
              title: '¿Por qué?',
              description: `Por favor, inserte los datos requeridos para crear la observación.`,
              controls: this.observationControls,
            };

            return this.dialog.open(FormDialogComponent, { inputs }).events.pipe(
              filter((e) => e.type === 'afterclose' && e.data),
              mergeMap((e) => this.observation(e.data)),
            );
          }

          return of(null);
        }),
        mergeMap(() => this.http.put(endpoint, { [status]: true })),
      )
      .subscribe(() => this.updated.emit());
  }

  approve(approve = true) {
    this.service.approve(this.ticket.id, approve).subscribe(() => this.updated.emit());
  }

  observation(body: any) {
    const endpoint = `//api/tickets/${this.ticket.id}/observation`;
    return this.http.post(endpoint, body);
  }

  cancel() {
    this.finish('canceled');
  }

  assign(user_id: number) {
    this.service.assign(this.ticket.id, user_id, true).subscribe(() => this.updated.emit());
  }

  unassign() {
    this.ux
      .confirm('¿Estás seguro de que quieres desasignar este usuario?')
      .pipe(
        filter((result) => !!result),
        mergeMap(() => this.service.assign(this.ticket.id, null, true)),
      )
      .subscribe(() => this.updated.emit());
  }

  toggleSubscription() {
    if (!this.ticket?.subscribed) {
      this.http
        .post('//api/subscribe', { ticket_id: this.ticket.id })
        .subscribe(() => this.updated.emit());
    } else {
      this.http.delete(`//api/unsubscribe/${this.ticket.id}`).subscribe(() => this.updated.emit());
    }
    this.ticket.subscribed = !this.ticket?.subscribed;
  }

  ticketLink() {
    const parsedDate = Date.parse(this.ticket.date);
    const date = format(parsedDate, 'yyyy-MM-dd');
    const url = `${window.location.host}/bridge/tickets?id=${this.ticket.id}&date=${date}`;
    this.clipboard.copy(url);
    this.ux.notifier.info('🔗 Link de Ticket copiado al Portapapeles');
  }

  related() {
    this.#dialog.open(TicketsListComponent, {
      data: { bookingId: this.ticket.booking_id },
    });
  }

  relatedTicket() {
    const accommodation_id = this.ticket?.accommodation_id;
    const booking_id = this.ticket?.booking_id;

    this.dialog.open(TicketFormComponent, {
      classes: 'without-min',
      inputs: {
        data: { accommodation_id, booking_id },
        hidden: {
          accommodation_id: !!accommodation_id,
          booking_id: !!booking_id,
        },
      },
    });
  }

  callAction(action) {
    action?.callback(this.ticket, () => this.updated.emit());
  }

  edit() {
    const ref = this.dialog.open(TicketFormComponent, {
      classes: 'without-min',
      inputs: { data: this.ticket },
    });
    ref.events.pipe(filter((e) => e.type === 'beforeclose')).subscribe(() => this.updated.emit());
  }

  move(direction: any) {
    const ticketId = this.around ? this.around[direction] : null;
    if (ticketId) {
      this.ref?.events.pipe(filter(({ type }) => type === 'beforeclose')).subscribe(() => {
        setTimeout(() => {
          const id = signal(ticketId);
          const inputs = { id };
          this.dialog.open(TicketViewComponent, { inputs });
        }, 100);
      });
      this.ref?.close();
    }
  }

  expenses() {
    const { id, accommodation_id } = this.ticket;
    console.log('Show expenses for ticket: ', { id, accommodation_id });
  }

  goToChannel(url: string) {
    window.open(url, '_blank');
  }

  openStatusMenu() {
    const canApprove = this.#gate.can('operation+3');
    const validToOpen =
      this.ticket?.assigned_id === this.auth?.user.id ||
      (this.ticket?.status === 'approval' && canApprove);

    if (validToOpen) {
      this.statusMenu.open();
    }
  }
}
