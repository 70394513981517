import { UXService } from '@/ux/services/ux.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { DialogService } from '@/ux/services/dialog';
import { catchError, defaultIfEmpty, EMPTY, filter, mergeMap, of, tap, throwIfEmpty } from 'rxjs';
import { FormDialogComponent } from 'src/app/legacy/components/form-dialog/form-dialog.component';
import { RuleControl } from 'src/app/legacy/objects/rule/rule.types';
import { ResourcesService } from 'src/app/legacy/services/resources.service';

@Injectable({ providedIn: 'root' })
export class TasksService {
  http = inject(HttpClient);
  dialog = inject(DialogService);
  service = inject(ResourcesService);
  ux = inject(UXService);

  evidence(task: any) {
    const url = `//api/tickets/${task.ticket_id}/observation`;
    const description = `Por favor, inserte los datos requeridos para crear la observación.`;
    const controls = [
      new RuleControl('files', 'Files', this.service, {
        type: 'file',
        accept: '*',
        multiple: true
      }),
      new RuleControl('text', 'Description', this.service, { type: 'textarea' })
    ];
    const inputs = { title: 'Evidencia', description, controls };
    const ref = this.dialog.open(FormDialogComponent, { inputs });

    return ref.events.pipe(
      filter((e) => e.type === 'beforeclose' && e.data),
      mergeMap(({ data }) => (data ? this.http.post(url, data) : EMPTY))
    );
  }

  list(ticketId: string | number) {
    const params = { ticketId };
    return this.http.get<any[]>(`//ops/tasks`, { params });
  }

  mark(task: any) {
    const request$ = this.http.put(`//ops/tasks/${task.id}/mark`, task);
    let observable$ = request$;

    if (task.done && task?.evidence) {
      observable$ = this.evidence(task).pipe(mergeMap(() => request$));
    }

    return observable$.pipe(
      throwIfEmpty(),
      catchError(() => of({ ...task, done: false }))
    );
  }

  create(body: any) {
    return this.http.post('//ops/tasks', body);
  }

  update(task: any) {
    return this.http.put(`//ops/tasks/${task.id}`, task);
  }

  delete(task: any) {
    if (task.id)
      return this.http.delete<any>(`//ops/tasks/${task.id}`).pipe(
        tap({
          next: () => this.ux.notifier.success('¡Tarea eliminada!'),
          error: () => this.ux.notifier.error('¡Error al eliminar Tarea!')
        })
      );
  }
}
