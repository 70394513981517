import { AuthService } from '@/core/services/auth.service';
import { Component, inject } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BgAuthComponent } from '../../components/bg-auth/bg-auth.component';
import { ButtonComponent } from '@/ux/components/button/button.component';
import { FormControlComponent } from '../../../ux/components/form-control/form-control.component';

@Component({
  standalone: true,
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BgAuthComponent,
    ButtonComponent,
    FormControlComponent,
  ],
  host: { class: 'page' },
})
export class ChangePasswordComponent {
  form: UntypedFormGroup;
  private token: string;
  route = inject(ActivatedRoute);
  router = inject(Router);

  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
  ) {
    this.token = this.route.snapshot.queryParamMap.get('token');
    if (!this.token) this.router.navigate(['/auth/login']);

    this.form = this.fb.group({
      password: ['', Validators.required],
      confirm: ['', Validators.required],
    });
    this.form.addValidators((f: UntypedFormGroup) => {
      const { password, confirm } = f.value;
      return password !== confirm ? { confirm: 'Invalid' } : null;
    });
  }

  submit() {
    const { password } = this.form.value;

    this.auth.updateme({ password }, this.token).subscribe(() => {
      this.form.reset();
      this.router.navigate(['/auth/login']);
    });
  }
}
