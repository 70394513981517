import { BookingService } from '@/operations/services/booking.service';
import { markForm } from '@/ux/utils/form.utils';
import { Component, inject, Input, OnInit, signal } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DialogRef, DialogService } from '@/ux/services/dialog';
import { addMonths, endOfMonth, format } from 'date-fns';
import { forkJoin } from 'rxjs';
import { fix } from 'src/app/core/helpers/object-helpers';
import { ResourcesService } from 'src/app/legacy/services/resources.service';
import { TicketViewComponent } from 'src/app/shared/components/ticket-view/ticket-view.component';
import { Ticket } from '../../models/ticket';
import { TicketInput, TicketsService } from '../../services/tickets.service';

@Component({
  selector: 'app-ticket-form',
  templateUrl: './ticket-form.component.html',
  styleUrls: ['./ticket-form.component.scss'],
})
export class TicketFormComponent implements OnInit {
  dialog = inject(DialogService);
  service = inject(TicketsService);
  resources = inject(ResourcesService);
  bookingService = inject(BookingService);
  fb = inject(UntypedFormBuilder);
  ref = inject(DialogRef, { optional: true });
  public months = 3;
  bookings: any[] = [];

  @Input()
  data?: Partial<TicketInput>;

  @Input()
  hidden?: Record<keyof TicketInput, boolean>;

  form: UntypedFormGroup;
  auxs: { [key: string]: any } = {
    actions: [],
    assignments: [],
    accommodations: [],
  };

  ngOnInit() {
    this.form = this.fb.group({ ...Ticket.formObject(this.data) });
    const auxts$ = {
      actions: this.resources.lite('/actions'),
      assignments: this.resources.lite('/auth/users'),
      accommodations: this.resources.lite('/accommodations'),
    };

    forkJoin(auxts$).subscribe((auxs) => (this.auxs = auxs));
    this.loadBookings();
  }

  loadBookings() {
    const currentDate = new Date();
    const start = format(currentDate, 'yyyy-MM-dd');
    const endDate = endOfMonth(addMonths(currentDate, this.months - 1));
    const end = format(endDate, 'yyyy-MM-dd');

    this.bookingService.list({ start, end }).subscribe((bookings) => {
      this.bookings = bookings.map((booking) => ({
        ...booking,
        info: `${booking.localizator} | ${booking.client || 'n/a'}`,
      }));
    });
  }

  submit(open?: boolean) {
    const data = fix(this.form.value);
    data.id = this.data?.id;

    this.service
      .updateOrCreate(data)
      .pipe(markForm(this.form))
      .subscribe((result: any) => {
        this.ref?.close(data);

        if (open) {
          const id = signal(result?.id);
          const config = { inputs: { id } };
          this.dialog.open(TicketViewComponent, config);
        }
      });
  }
}
