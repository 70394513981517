import { ConfigService } from '@/core/services/config.service';
import { TagsInputComponent } from '@/shared/components/tags-input/tags-input.component';
import { Option } from '@/ux/directives/option.directive';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Input,
  ViewChild,
  inject
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { DialogRef } from '@/ux/services/dialog';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';
import { Observable } from 'rxjs';
import { editorModules } from './accommodation-instructions.constants';

@Component({
  selector: 'app-accommodation-instructions',
  standalone: true,
  imports: [
    CommonModule,
    TagsInputComponent,
    Option,
    QuillEditorComponent,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './accommodation-instructions.component.html',
  styleUrl: './accommodation-instructions.component.scss'
})
export class AccommodationInstructionsComponent implements AfterViewInit {
  #config = inject(ConfigService);

  @ViewChild(QuillEditorComponent)
  editor?: QuillEditorComponent;

  @Input()
  data?: any = {};

  actions$: Observable<any[]>;
  ref?: DialogRef = inject(DialogRef, { optional: true });
  form = new UntypedFormGroup({
    actions: new UntypedFormControl([], [Validators.required]),
    template: new UntypedFormControl({}, [Validators.required])
  });
  quillModules: QuillModules = editorModules;

  constructor() {
    this.actions$ = this.#config.get<any[]>('actions');
  }

  ngAfterViewInit() {
    this.form.patchValue(this.data);
  }

  save() {
    if (!this.form.valid) {
      return;
    }

    this.ref?.close(this.form.value);
  }
}
