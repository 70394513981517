<app-bg-auth></app-bg-auth>
<section class="forgot-content">
  <h2 class="font-semibold text-3xl text-primary self-center md:self-start">
    ¡Enviaremos un correo para recuperar tu contraseña!
  </h2>
  <form #formEl class="flex-initial" [formGroup]="form" (ngSubmit)="recover()">
    <form-control label="Correo" name="email">
      <input class="input !bg-white/50" formControlName="email" type="email" />
      <a class="font-normal text-primary ml-auto" href="auth/login">Olvidalo, ya la recordé</a>
    </form-control>
    <div class="form__actions">
      <app-button type="submit" class="btn-primary rounded-full w-full md:w-48 self-center" [block]="true"
        [disabled]="form.invalid">
        <span class="font-semibold">Enviar</span>
      </app-button>
    </div>
  </form>
</section>