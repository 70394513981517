import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Input,
  Optional,
  Output,
  TemplateRef
} from '@angular/core';
import { Observable } from 'rxjs';
import { WaitDirective } from '@/ux/directives/wait.directive';
import { DialogRef } from '@/ux/services/dialog';
import { FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  selector: 'app-list-old',
  templateUrl: './list-old.component.html',
  styleUrls: ['./list-old.component.scss'],
  imports: [CommonModule, WaitDirective, FormsModule]
})
export class ListOldComponent {
  @ContentChild(TemplateRef)
  templateContent?: TemplateRef<any>;

  @Input()
  template?: TemplateRef<any>;

  @Input()
  items: Observable<any[]>;

  @Input()
  title?: string;

  @Input()
  hideSearch?: boolean;

  @Input()
  btns?: { [key: string]: string };

  @Input()
  search?: string;

  @HostBinding('attr.item-type')
  @Input()
  type: string;

  @Output('search')
  searchEmitter = new EventEmitter<string>();

  track = (i: number, item: any) => item?.id ?? i;

  private timeout?: any;

  constructor(@Optional() public ref?: DialogRef) {}

  emitExec(value: string, data?: any) {
    this.ref?.emit({ type: 'exec', value, data, instance: this });
  }

  emitSearch(value = '') {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.searchEmitter.emit(value);
      this.ref?.emit({ type: 'search', instance: this });
    }, 639);
  }
}
