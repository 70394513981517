import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Form } from '@pms/models/form';
import { DialogRef } from '@/ux/services/dialog';
import { MinputComponent } from 'src/app/ux/components/minput/minput.component';
import { Option } from 'src/app/ux/directives/option.directive';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-form',
  standalone: true,
  imports: [CommonModule, MinputComponent, Option, FormsModule],
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  @Input()
  hideHeader = false;

  @Input()
  hideFooter = false;

  @Input()
  form: Form = new Form();

  @Output('submit')
  submitEvent = new EventEmitter<any>();

  constructor(@Optional() public ref?: DialogRef) {}

  ngOnInit() {
    this.form.content = this.form.content || {};
  }

  submit() {
    this.submitEvent.emit(this.form);
  }
}
