<div class="item">
  <div class="item__detail">
    <span>Tickets Asignados</span>
    <span class="item__info">
      <i class="ri-information-line"></i>
      Nuevos tickets asignados a mi usuario.
    </span>
  </div>
  <input
    class="toggle toggle-primary"
    type="checkbox"
    [checked]="assignment"
    (change)="onChange($event, 'assignment')"
    checked=" checked"
  />
</div>

<div class="item">
  <div class="item__detail">
    <span>Mención en Comentario</span>
    <span class="item__info">
      <i class="ri-information-line"></i>
      Me mencionan en un nuevo comentario.
    </span>
  </div>
  <input
    class="toggle toggle-primary"
    type="checkbox"
    [checked]="mention"
    (change)="onChange($event, 'mention')"
    checked=" checked"
  />
</div>

<div class="item">
  <div class="item__detail">
    <span>Nuevos Comentarios</span>
    <span class="item__info">
      <i class="ri-information-line"></i>
      Tickets en los que estoy subscrito.
    </span>
  </div>
  <input
    class="toggle toggle-primary"
    type="checkbox"
    [checked]="newComment"
    (change)="onChange($event, 'newComment')"
    checked="checked"
  />
</div>

<div class="item">
  <div class="item__detail">
    <span>Cambios en Tickets</span>
    <span class="item__info">
      <i class="ri-information-line"></i>
      Tickets en los que estoy subscrito.
    </span>
  </div>
  <input
    class="toggle toggle-primary"
    type="checkbox"
    [checked]="ticketModified"
    (change)="onChange($event, 'ticketModified')"
    checked="checked"
  />
</div>
