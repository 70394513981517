<div class="flex items-center mb-1 gap-2">
  <span class="avatar">
    <div class="w-6 rounded-full border border-base-200">
      <img [src]="data()?.customization?.logo" onError="this.onerror=null;this.src='/assets/images/tenant.jpg';" />
    </div>
  </span>
  <h2 class="inline-flex items-center capitalize text-force-ellipsis">
    {{ data()?.name || data()?.id }}
  </h2>
  <div class="flex items-center gap-2 ml-auto">
    <button class="btn btn-icon text-primary" (click)="seed()">
      <i class="ri-seedling-line"></i>
    </button>
    <button class="btn btn-icon text-primary" (click)="accommodations()">
      <i class="ri-home-gear-line"></i>
    </button>
    <button class="btn btn-icon text-primary" (click)="edit()">
      <i class="ri-pencil-line"></i>
    </button>
  </div>
</div>
<p class="mb-auto p-2">{{ data().customization?.slogan ?? 'Sin eslogan' }}</p>
<div class="divider mt-auto mb-0"></div>
<div class="flex items-center justify-end gap-2">
  <i class="ri-flask-fill text-base-200" [class.text-primary]="data().demo"
    [matTooltip]="data().demo ? 'Es demo' : 'No es demo'"></i>
  <i class="ri-plug-fill text-base-200" [class.text-primary]="data().parent"
    [matTooltip]="data().parent ? 'Sub-entorno de ' + data().parent : 'Entorno raiz'"></i>
  <i class="ri-shut-down-fill text-base-200" [class.text-success]="data().active && data().seeded"
    [class.text-error]="!data().active" [class.text-warning]="!data().seeded"
    [matTooltip]="!data().active ? 'Inactivo' : !data().seeded ? 'Sin sembrar' : 'Activo'"></i>
</div>