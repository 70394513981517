import { Component } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-password-form',
  standalone: true,
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.scss'],
  imports: [FormsModule, ReactiveFormsModule]
})
export class PasswordFormComponent {
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService
  ) {
    this.form = this.fb.group({
      password: ['', Validators.required],
      confirm: ['', Validators.required]
    });
    this.form.addValidators((f: UntypedFormGroup) => {
      const { password, confirm } = f.value;
      return password !== confirm ? { confirm: 'Invalid' } : null;
    });
  }

  submit() {
    const { password } = this.form.value;
    this.auth.updateme({ password }).subscribe(() => {
      this.form.reset();
    });
  }
}
