import { HttpClient } from '@angular/common/http';
import { Injectable, Injector, inject } from '@angular/core';
import { DialogService } from '@/ux/services/dialog';
import { Observable, map } from 'rxjs';
import { fix } from 'src/app/core/helpers/object-helpers';

@Injectable({ providedIn: 'root' })
export class ResourcesService {
  dialog = inject(DialogService);
  injector = inject(Injector);
  http = inject(HttpClient);

  one(endpoint: string, id: number, options?: any) {
    const uri = `//api${endpoint}/${id}`;
    return this.http.get<any>(uri, options ?? {});
  }

  list(endpoint: string, options?: any) {
    const uri = `//api${endpoint}`;
    options = fix(options);

    return this.http
      .get(uri, { ...options, observe: 'response' })
      .pipe(map((r: any) => (options.pure ? r : r.body || [])));
  }

  lite(endpoint: string, options?: any): Observable<any[]> {
    return this.http.get(`//api${endpoint}/lite`, options) as any;
  }

  save<T = any>(endpoint: string, body: any, options?: any) {
    const url = `//api${endpoint}`;

    return this.http.post<T>(url, body, options);
  }

  update<T = any>(
    endpoint: string,
    id: number | string,
    body: any,
    options: any = {}
  ) {
    const url = `//api${endpoint}/${id}`;
    const isFakePut = body instanceof FormData && body.get('_method') === 'put';

    if (isFakePut) return this.http.post<T>(url, body, options);
    return this.http.put<T>(url, body, options);
  }

  delete(endpoint: string, id: number | string) {
    return this.http.delete(`//api${endpoint}/${id}`);
  }
}
