import { DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from './components/button/button.component';
import { DateMonthComponent } from './components/date-month/date-month.component';
import { DateComponent } from './components/date/date.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { JsonCardComponent } from './components/json-card/json-card.component';
import { ListOldComponent } from './components/list/list-old.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MessageComponent } from './components/message/message.component';
import { MinputComponent } from './components/minput/minput.component';
import { StaticMapComponent } from './components/static-map/static-map.component';
import { TabComponent } from './components/tab/tab.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { SelectComponent } from './controls/select/select.component';
import { TogglerComponent } from './controls/toggler/toggler.component';
import { QuickeditComponent } from './dialogs/quickedit/quickedit.component';
import { CopyDirective } from './directives/copy.directive';
import { Option } from './directives/option.directive';
import { QuickeditDirective } from './directives/quickedit.directive';
import { ScrollhDirective } from './directives/scrollh.directive';
import { TexeditDirective } from './directives/texedit.directive';
import { TriggerFor } from './directives/triggerfor.directive';
import { WaitDirective } from './directives/wait.directive';
import { ContrastPipe } from './pipes/contrast.pipe';
import { OrderPipe } from './pipes/order.pipe';
import { FilesDialogComponent } from '@/shared/components/files-dialog/files-dialog.component';
import { DialogWrapperComponent } from './components/dialog-wrapper/dialog-wrapper.component';
import { DialogService } from './services/dialog';

const publics = [
  TabsComponent,
  TabComponent,
  MessageComponent,
  QuickeditComponent,
  DialogWrapperComponent,
];

const STANDALONE: any[] = [
  // components
  JsonCardComponent,
  TogglerComponent,
  ListOldComponent,
  DropdownComponent,
  SelectComponent,
  StaticMapComponent,
  MinputComponent,
  LoadingComponent,
  FilesDialogComponent,

  // directives
  Option,
  WaitDirective,
  DateComponent,
  DateMonthComponent,
  ListOldComponent,
  WaitDirective,
  TriggerFor,
  ScrollhDirective,
  CopyDirective,
  TexeditDirective,
  QuickeditDirective,

  // pipes
  OrderPipe,
  ContrastPipe,
  JsonCardComponent,
  ButtonComponent,
];

@NgModule({
  declarations: [...publics],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    DialogModule,
    MatDatepickerModule,
    ...STANDALONE,
  ],
  exports: [...publics, ...STANDALONE],
  providers: [DialogService],
})
export class UXModule {}
