<ng-template let-ticket="item" #template>
  <app-ticket-card class="cursor-pointer" [ticketId]="ticket.id" [ticket]="ticket" (changed)="datasource.load(true)">
  </app-ticket-card>
</ng-template>

<app-list [datasource]="datasource" [template]="template" [hideMenu]="true">
  <ng-container bar>
    <button class="btn btn-icon bg-transparent shadow-none">
      <i class="ri-add-line text-success !text-xl p-5"></i>
    </button>
    <button class="btn btn-icon  ml-auto btn-close bg-transparent shadow-none" (click)="ref.close()">
      <i class="ri-close-line !text-2xl"></i>
    </button>
  </ng-container>
</app-list>