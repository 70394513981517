import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { DialogService } from '@/ux/services/dialog';
import { EMPTY, catchError, map } from 'rxjs';
import { DateRangeComponent } from '../components/date-range/date-range.component';

@Injectable({ providedIn: 'root' })
export class ReportService {
  constructor(private dialog: DialogService, private http: HttpClient) {}

  private downLoadFile(data: any, type: string, fileName: string) {
    let blob = new Blob([data], { type });
    var url = window.URL.createObjectURL(blob);
    var anchor = document.createElement('a');
    anchor.download = fileName;
    anchor.href = url;
    anchor.click();
  }

  selectPeriod(): Promise<{ from: String; to: String } | null> {
    return new Promise((resolve, reject) => {
      const ref = this.dialog.open(DateRangeComponent, {
        classes: 'without-min'
      });
      const sub = ref.events.subscribe((event) => {
        resolve(event.type === 'beforeclose' && event.data ? event.data : null);
      });
    });
  }

  generateExpenseReport(params = {}) {
    const url = '//api/pms/accommodations/reports-expenses';
    const options: any = { params, responseType: 'blob' };

    this.http
      .get(url, options)
      .pipe(
        catchError((error) => {
          console.error(error);
          return EMPTY;
        })
      )
      .subscribe((response) => {
        this.downLoadFile(response, 'application/ms-excel', 'gastos.xlsx');
      });
  }

  generateReportByAccommodation(params: Params) {
    return this.http
      .get('//api/pms/reports/accommodations', {
        params,
        responseType: 'blob'
      })
      .pipe(
        map((response) => {
          this.downLoadFile(response, 'blob', 'Reporte-reservas.xlsx');
        })
      );
  }
}
