import { Component, HostListener, Input, OnInit, signal } from '@angular/core';
import { DialogService } from '@/ux/services/dialog';
import { AppService } from 'src/app/core/services/app.service';
import { TicketViewComponent } from '../ticket-view/ticket-view.component';

@Component({
  selector: 'app-ticket-li',
  templateUrl: './ticket-li.component.html',
  styleUrls: ['./ticket-li.component.scss']
})
export class TicketLiComponent implements OnInit {
  @Input() ticket?: any;

  constructor(private app: AppService, private dialog: DialogService) {}

  ngOnInit(): void {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    if (!event.ctrlKey) this.app.emit('app:search', false);
    const id = signal(this.ticket?.id);
    const inputs = { id };
    this.dialog.open(TicketViewComponent, { inputs });
  }
}
