import { NotificationsService } from '@/shared/services/notifications.service';
import {
  Component,
  ElementRef,
  HostBinding,
  inject,
  Renderer2,
  viewChild,
  viewChildren,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterLinkActive } from '@angular/router';
import { environment } from '@environments/environment';
import { DialogService } from '@/ux/services/dialog';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { TenancyService } from 'src/app/admin/services/tenancy.service';
import { AppService } from 'src/app/core/services/app.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { NotificationsComponent } from 'src/app/shared/components/notifications/notifications.component';
import { SharedModule } from '../../shared.module';

@Component({
  standalone: true,
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  imports: [SharedModule],
})
export class NavComponent {
  app = inject(AppService);
  auth = inject(AuthService);
  tenancy = inject(TenancyService);
  translate = inject(TranslateService);
  dialog = inject(DialogService);
  router = inject(Router);
  notifications = inject(NotificationsService);
  render2 = inject(Renderer2);

  pointer = viewChild<ElementRef>('pointer');
  links = viewChildren(RouterLinkActive);

  @HostBinding('class.more')
  more: boolean = false;

  language = 'es';
  dev = !environment.production;

  get tenant() {
    const tenant = this.tenancy.tenant;
    return tenant?.name || tenant?.id;
  }

  constructor() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed(),
      )
      .subscribe(() => setTimeout(() => this.update()));
  }

  update() {
    this.more = false;
    const active = this.links()?.find((link) => link.isActive) as any;
    const element = active.element?.nativeElement as HTMLElement;
    const pointer = this.pointer()?.nativeElement as HTMLElement;
    const elementStyles = getComputedStyle(element);

    if (!element || elementStyles.display === 'none') {
      this.render2.setStyle(pointer, 'height', '0');
      this.render2.setStyle(pointer, 'width', '0');
      return;
    }

    if (element && pointer) {
      const deltaTop = element.offsetTop - pointer.offsetTop;
      const deltaLeft = element.offsetLeft - pointer.offsetLeft;
      const dirTop = deltaTop > 0 ? 1 : -1;

      dirTop < 0 && this.render2.setStyle(pointer, 'top', `${element.offsetTop}px`);
      dirTop < 0 && this.render2.setStyle(pointer, 'left', `${element.offsetLeft}px`);
      this.render2.setStyle(pointer, 'height', `${Math.abs(deltaTop) + element.offsetHeight}px`);
      this.render2.setStyle(pointer, 'width', `${Math.abs(deltaLeft) + element.offsetWidth}px`);

      setTimeout(() => {
        this.render2.setStyle(pointer, 'left', `${element.offsetLeft}px`);
        this.render2.setStyle(pointer, 'top', `${element.offsetTop}px`);
        this.render2.setStyle(pointer, 'width', `${element.offsetWidth}px`);
        this.render2.setStyle(pointer, 'height', `${element.offsetHeight}px`);
      }, 100);
    }
  }

  toggleLanguage() {
    this.language = this.language === 'en' ? 'es' : 'en';
    this.translate.use(this.language);
  }

  notification() {
    this.dialog.open(NotificationsComponent, { type: 'shy' });
  }
}
