import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DialogService } from '@/ux/services/dialog';
import { map, catchError, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BookingFormComponent } from '../components/booking-form/booking-form.component';
import { Booking } from '../models/booking';
import { UXService } from 'src/app/ux/services/ux.service';

@Injectable({ providedIn: 'root' })
export class BookingsService {
  refreshManagement$ = new BehaviorSubject<boolean>(true);
  constructor(
    private http: HttpClient,
    private dialog: DialogService,
    private ux: UXService
  ) {}

  form(data?: Partial<Booking>) {
    const id = data?.id;
    const ref = this.dialog.open(BookingFormComponent, {
      classes: 'without-min',
      inputs: { data }
    });

    return new Observable((observer) => {
      ref.events.subscribe(({ type, data: body }) => {
        if (type === 'beforeclose') observer.complete();
        else if (type === 'custom') {
          const obs = id ? this.update(id, body) : this.store(body);
          obs.subscribe((item) => {
            observer.next(item);
            if (item?.id) ref.close();
          });
        }
      });
    });
  }

  store(data: Partial<Booking>) {
    return this.http.post<any>(`//api/pms/bookings`, data).pipe(
      tap({
        next: () => this.ux.notifier.success('¡Reserva creada!'),
        error: () => this.ux.notifier.error('¡Error al crear reserva!')
      })
    );
  }

  update(id: number | string, data: Partial<Booking>) {
    return this.http.put<any>(`//api/pms/bookings/${id}`, data).pipe(
      tap({
        next: () => this.ux.notifier.success('¡Reserva actualizada!'),
        error: () => this.ux.notifier.error('¡Error al actualizar reserva!')
      })
    );
  }

  localize(localizator: string) {
    return this.http
      .get<any>(`//api/pms/bookings/localize/${localizator}`)
      .pipe(
        catchError(() => of(null)),
        map((response) => (response ? new Booking(response) : null))
      );
  }
}
