import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppService } from '@core/services/app.service';
import { DialogService } from '@/ux/services/dialog';
import { Checklist } from '@operations/models/checklist';
import { filter, map, mergeMap } from 'rxjs';
import { FormDialogComponent } from 'src/app/legacy/components/form-dialog/form-dialog.component';
import { Checklist as ChecklistResource } from 'src/app/legacy/resources/checklist';
import { ResourcesService } from 'src/app/legacy/services/resources.service';

@Component({
  selector: 'app-checklist-card',
  standalone: true,
  imports: [CommonModule, MatTooltipModule],
  templateUrl: './checklist-card.component.html',
  styleUrls: ['./checklist-card.component.scss']
})
export class ChecklistCardComponent implements OnInit {
  checklists = inject(ResourcesService);
  dialog = inject(DialogService);
  app = inject(AppService);

  @Input()
  data: Checklist;
  withEvidence: number;

  ngOnInit() {
    this.withEvidence = this.data.tasks?.filter((task) => task.evidence).length;
  }

  edit() {
    const resource = ChecklistResource;
    const endpoint = resource.endpoint;
    const inputs = {
      resource,
      data: this.data,
      title: 'Editar Checklist',
      controls: resource.getControls(this.checklists)
    };

    this.dialog
      .open(FormDialogComponent, { inputs })
      .events.pipe(
        filter((e) => e.type === 'beforeclose' && e.data),
        map(({ data }) => data),
        mergeMap((d) => this.checklists.update(endpoint, this.data?.id, d))
      )
      .subscribe(() => this.app.emit('page:load'));
  }
}
