import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

export interface IntegrationProviderField {
  key: string;
  title: string;
  optional?: boolean;
  secret?: boolean;
}

export class IntegrationProvider {
  code: string;
  name!: string;
  color!: string;
  image!: string;
  fields?: IntegrationProviderField[] = [];

  constructor(obj?: Partial<IntegrationProvider>) {
    Object.assign(this, obj);
  }

  getForm() {
    const fields: Record<string, any> = {};

    for (const field of this.fields) {
      const validators = [];
      if (!field.optional) {
        validators.push(Validators.required);
      }

      fields[field.key] = new UntypedFormControl('', validators);
    }

    return new UntypedFormGroup(fields);
  }
}
