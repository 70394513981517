<form [formGroup]="form" autocomplete="off" class="!overflow-hidden">
  <div class="form__control">
    <span class="mb-4">Nueva contraseña </span>
    <input
      class="input"
      type="password"
      formControlName="password"
      value=""
      autocomplete="new-password"
    />
  </div>
  <div class="form__control">
    <span class="mb-4"> Confirmar contraseña</span>
    <input
      class="input"
      type="password"
      formControlName="confirm"
      value=""
      autocomplete="new-password"
    />
  </div>

  <div class="flex ml-auto mt-auto">
    <button [disabled]="form.invalid" (click)="submit()" class="btn btn-success text-white shadow-md">
      Guardar
      <i class="ri-lock-line"></i>
    </button>
  </div>
</form>
