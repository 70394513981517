import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  QueryList,
  ViewChild,
  ViewChildren,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogRef } from '@/ux/services/dialog';
import { filter } from 'rxjs';
import { PaginationComponent } from 'src/app/ux/components/pagination/pagination.component';
import { WaitDirective } from 'src/app/ux/directives/wait.directive';
import { Notification } from '../../../system/models/notifications';
import { NotificationsService } from '../../services/notifications.service';
import { NotificationCardComponent } from '../notification-card/notification-card.component';
import { ListDatasource } from 'src/app/ux/components/list/list.datasource';
import { ListLoaderArgs } from 'src/app/ux/components/list/list.types';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    WaitDirective,
    PaginationComponent,
    NotificationCardComponent,
    FormsModule,
  ],
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent {
  service = inject(NotificationsService);
  router = inject(Router);
  chd = inject(ChangeDetectorRef);
  ref?: DialogRef = inject(DialogRef, { optional: true });

  @ViewChild('container')
  private container!: ElementRef;

  @ViewChildren('item', { read: ElementRef })
  private items!: QueryList<ElementRef>;
  tracker = (i: number, el: any) => el.id;

  datasource = new ListDatasource<Notification>(this.loader.bind(this));

  get readed() {
    return this.datasource.getOption('readed') ?? false;
  }

  set readed(value: boolean) {
    this.datasource.setOption('readed', value);
  }

  ngAfterViewInit() {
    const root = this.container.nativeElement;
    const options = { root, rootMargin: '300px', threshold: 1 };
    const observer = new IntersectionObserver((entries) => {
      const intersecting = entries.some((entry) => entry.isIntersecting);
      if (intersecting) {
        entries.forEach((entry) => observer.unobserve(entry.target));
        this.datasource?.load();
        this.chd.markForCheck();
      }
    }, options);

    this.items.changes
      .pipe(filter((items) => items.length))
      .subscribe((items) => observer.observe(items.last.nativeElement));

    this.datasource.items$.subscribe(() => this.chd.markForCheck());
  }

  loader(args: ListLoaderArgs) {
    const { skip, limit, options } = args;
    const { search = '', readed = false } = options ?? {};
    const requestParams = {
      search,
      skip,
      limit,
      readed,
    };

    return this.service.list(requestParams);
  }

  readAll() {
    this.service.readAll().subscribe(() => this.datasource.load(true));
  }
}
