import {
  HttpEvent,
  HttpHandlerFn,
  HttpHeaders,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { headersToRecord } from '../helpers/http.helpers';
import { AppService } from '../services/app.service';
import { CacheService } from '../services/cache.service';

export const cachingInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  const app = inject(AppService);
  const cache = inject(CacheService);

  if (!req.headers.get('app-cache') || req.method.toUpperCase() !== 'GET') {
    return next(req);
  }

  const header = req.headers.get('app-cache');
  const config = header === 'default' ? `default:320` : header;
  const [name, timeStr, command] = config.split(':');
  const tenant = app.globals.deep('tenancy.tenant.id') ?? 'central';
  const userId = app.globals.deep('session.user.id') ?? '0';
  const path = req.urlWithParams.replace(environment.apiUrl, '');
  const prefix = `${tenant}.${userId}.${path}`;
  const key = `${prefix}.${name}`;
  const value = command === 'reload' ? null : cache.get(key);

  if (!value) {
    return next(req).pipe(
      tap((e) => {
        if (!(e instanceof HttpResponse) || !e.ok) return;

        const { url, body, status, statusText } = e;
        const time = +(timeStr ?? '320');
        const headers = headersToRecord(e.headers);
        const record = { url, body, status, statusText, headers };

        cache.set(key, record, time);
      }),
    );
  }

  return of(new HttpResponse({ ...value, headers: new HttpHeaders(value.headers) }));
};
