import { Component, EventEmitter, input, Input, OnInit, Optional, Output } from '@angular/core';
import { DialogRef } from '@/ux/services/dialog';
import { Option } from '../../../ux/directives/option.directive';

type MultiSelectOption = Partial<
  Omit<Option & { checked?: boolean }, 'ngAfterViewInit'>
>;

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit {
  @Input() options: MultiSelectOption[] = [];
  @Input() values: any[];
  @Input() search: string = '';
  @Input() focus: boolean;
  @Input() multiple: boolean = true;
  @Input() controlType: string = 'switch';
  @Input() submitLabel: string = 'Enviar';
  @Input() submitIcon: string = 'ri-check-line';

  @Output() selectionChange = new EventEmitter<any[]>(); 

  type = input<'modal' | 'selector'>('modal');

  constructor(@Optional() public ref?: DialogRef) {}

  ngOnInit() {
    this.options.forEach((o) => {
      const strfy = JSON.stringify;
      o.checked = this.values?.some((v) => strfy(v) === strfy(o.value));
    });
  }

  clickOption(option: MultiSelectOption) {
    if (!this.multiple) this.submit(option);
  }

  submit(option?: MultiSelectOption) {
    let result = option?.value;

    if (!this.multiple) {
      return this.ref?.close(result);
    }

    result = this.options
      .filter((option) => option.checked)
      .map((option) => option.value);

    this.ref?.close(result);
  }

  emitChange() {
    const result = this.options
      .filter(option => option.checked)
      .map(option => option.value);

    this.selectionChange.emit(result);
  }
}
