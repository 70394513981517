import {
  HttpInterceptorFn,
  HttpRequest,
  HttpHandlerFn,
  HttpEvent,
  HttpResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { mergeHeaders } from 'src/app/core/helpers/http.helpers';
import localforage from 'localforage';

export const headersInterceptor: HttpInterceptorFn = (
  request: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  if (!request.url.startsWith(environment.apiUrl)) return next(request);
  return pre(request).pipe(
    mergeMap((req) => next(req)),
    tap((event) => pos(event)),
  );
};

function pre(request: HttpRequest<any>) {
  return new Observable<HttpRequest<any>>((observer) => {
    localforage
      .getItem<any>('headers')
      .then((headers) => {
        if (request.headers.get('auth') === 'none') {
          headers = headers || {};
          delete headers['Authorization'];
        }

        const merged = mergeHeaders(request.headers, headers || {});
        observer.next(request.clone({ headers: merged }));
      })
      .catch((e) => {
        console.warn(`Skip merge headers: ${e}`);
        observer.next(request);
      })
      .finally(() => observer.complete());
  });
}

function pos(event: HttpEvent<any>) {
  if (event instanceof HttpResponse && event.headers) {
    const headers =
      event.headers instanceof HttpHeaders ? event.headers : new HttpHeaders(event.headers);

    localforage
      .getItem<any>('headers')
      .then((record) => {
        headers.keys()?.forEach((k) => {
          if (k.toLowerCase().startsWith('set-'))
            record[k.toLowerCase().replace('set-', '')] = headers.get(k);
        });
        localforage.setItem('headers', record);
      })
      .catch((e) => console.warn(`Skip store headers: ${e}`));
  }
}
