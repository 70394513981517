import { CommonModule } from '@angular/common';
import { Component, Input, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DialogRef } from '@/ux/services/dialog';
import { Form } from '@pms/models/form';
import { AccommodationFormsService } from '@pms/services/accommodation-forms.service';
import { FormsService } from '@pms/services/forms.service';
import { Observable } from 'rxjs';
import { SelectComponent } from 'src/app/ux/controls/select/select.component';
import { Option } from 'src/app/ux/directives/option.directive';
import { FormComponent } from '../form/form.component';
import { AccommodationsService } from '@pms/services/accommodations.service';

@Component({
  selector: 'app-form-assignment',
  standalone: true,
  imports: [CommonModule, SelectComponent, Option, FormsModule, FormComponent],
  templateUrl: './form-assignment.component.html',
  styleUrls: ['./form-assignment.component.scss']
})
export class FormAssignmentComponent {
  accommodations$?: Observable<any[]>;
  forms$?: Observable<any[]>;

  @Input()
  form: Form;

  @Input()
  result: Form = new Form({ content: {} });

  @Input()
  disableForm = false;

  @Input()
  disableAccommodation = false;

  // todo: Update this when dialogs function as output-based pipelines
  // @Output('submit')
  // submitEmitter = new EventEmitter<any>();

  constructor(
    public forms: FormsService,
    public accommodations: AccommodationsService,
    public accommodationForms: AccommodationFormsService,
    @Optional() public ref?: DialogRef
  ) {
    this.load();
  }

  formChanged(id: string | number, forms: Form[]) {
    if (!id) return;
    const form = forms.find((f) => f.id === id);

    this.result.title = form?.title || '';
    this.result.content = form?.content || {};
    this.result.controls = form?.controls || [];
  }

  load() {
    this.forms$ = this.forms.list();
    this.accommodations$ = this.accommodations.list();
  }

  submit(form: Form) {
    this.result.content = form.content;

    this.accommodationForms
      .store(form)
      .subscribe((r) => r && this.ref?.close(r));
  }
}
