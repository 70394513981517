import { Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Entity } from './base';

export class User extends Entity<User> {
  name: string;
  surname: string;
  email: string;
  image?: string;
  dni?: string;
  exiled?: boolean;
  corrupted?: boolean;
  phones?: string[];
  role_id?: number;
  role?: string;
  extras?: any;
  is_ai?: boolean;
  configs?: any;

  get fullname() {
    return `${this.name || 'Unamed'} ${this.surname || ''}`;
  }

  get phone() {
    return this.phones ? this.phones.pop() : '';
  }

  constructor(obj?: Partial<User>) {
    super(obj);
    Object.assign(this, obj);
    if (this.image && !this.image?.startsWith('http')) this.image = environment.apiUrl + this.image;
  }

  static formObject(user?: Partial<User>) {
    let phone = '';
    if (typeof user.phones === 'string') user.phones = JSON.parse(user.phones);
    phone = user?.phones?.[0] || '';

    return {
      email: [user?.email, [Validators.required]],
      name: [user?.name, [Validators.required]],
      surname: [user?.surname, []],
      image: [user?.image, []],
      dni: [user?.dni, []],
      phone: [phone, []],
      role_id: [user?.role_id, []],
      password: [undefined, []],
    };
  }
}
