<img
  [src]="providerData.image"
  class="z-0 pointer-events-none w-full h-full object-contain"
/>
<b class="mt-auto absolute bottom-2"> {{ item().name }} </b>
<div class="actions">
  <button *can="'su'" class="btn btn-icon btn-base-100 p-2 m-1" (click)="remove()">
    <i class="ri-delete-bin-line"></i>
  </button>
</div>
