import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { JoinmeComponent } from './components/joinme/joinme.component';
import { AuthGuard } from './guards/auth.guard';
import { GateGuard } from './guards/gate.guard';
import { BridgeComponent } from './pages/bridge/bridge.component';
import { AuthService } from './services/auth.service';
import { ParametersService } from './services/parameters.service';

@NgModule({
  declarations: [BridgeComponent, JoinmeComponent],
  providers: [AuthService, AuthGuard, GateGuard, ParametersService],
  imports: [BrowserModule, BrowserAnimationsModule, CommonModule, SharedModule, RouterModule],
  exports: [BridgeComponent],
})
export class CoreModule {}
