<app-bg-auth></app-bg-auth>
<section class="login-content">
  <h2 class="font-semibold text-6xl text-primary self-center md:self-start">Kairós</h2>
  <form #formEl class="flex-initial" [formGroup]="form" (ngSubmit)="login()">
    <form-control label="Correo" name="email">
      <input class="input !bg-white/50" formControlName="email" type="email" />
    </form-control>
    <form-control label="Contraseña" name="password">
      <input class="input !bg-white/50" formControlName="password" type="password" />
      <a class="font-normal text-primary ml-auto" href="auth/forgot">¿Has olvidado tu contraseña?</a>
    </form-control>
    <div class="form__actions">
      <app-button type="submit" class="btn-primary rounded-full w-full md:w-48 self-center" [block]="true"
        [disabled]="form.invalid">
        <span class="font-semibold">Iniciar Sesión</span>
      </app-button>
    </div>
  </form>
</section>