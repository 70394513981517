<div class="indicator">
  <span *ngIf="!notification.read_at" class="indicator-item indicator-start badge badge-secondary badge-xs"></span>
  <div [ngClass]="getBg()" class="w-14 h-14 rounded-full flex items-center justify-center">
    <i [ngClass]="getIcon()" class="rounded-full text-white !text-3xl"></i>
  </div>
</div>
<div class="flex flex-col items-start gap-1 overflow-hidden self-start h-full py-2 w-full">
  <strong class="text-lg font-semibold text-force-ellipsis"> {{ data.title }} </strong>
  <span class="text-sm text-force-ellipsis"> {{ data.message }} </span>
  <strong class="text-sm text-primary text-force-ellipsis" *ngIf="data.accommodation">
    <i class="ri-community-line"></i>
    <span> {{ data.accommodation }} </span>
  </strong>
  <small class="ml-auto mt-auto text-xs text-neutral text-opacity-70">
    {{ notification.created_at | date : 'medium' }}
  </small>
</div>