import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs';
import { UXService } from '@/ux/services/ux.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Injectable({ providedIn: 'root' })
export class AkilesService {
  #http = inject(HttpClient);
  #ux = inject(UXService);
  clipboard = inject(Clipboard);

  constructor() {}

  link(booking_id: number | string) {
    return this.#http.get(`//api/integration/akiles/link/${booking_id}`).pipe(
      tap({
        error: () => this.#ux.notifier.error('¡No se pudo obtener el link de Akiles!')
      })
    );
  }

  copyLink(booking_id: number | string, loadingCopy: any) {
    loadingCopy.set(true);

    this.link(booking_id).subscribe({
      next: (data: any) => {
        const url = data.link;
        this.clipboard.copy(url);
        this.#ux.notifier.info('🔗 Link de Akiles copiado al Portapapeles');
        loadingCopy.set(false);
      },
      error: () => {
        loadingCopy.set(false);
      }
    });
  }

  openLink(booking_id: number | string, loadingOpen: any) {
    loadingOpen.set(true);

    this.link(booking_id).subscribe({
      next: (data: any) => {
        const url = data.link;
        window.open(url, '_blank');
        loadingOpen.set(false);
      },
      error: () => {
        loadingOpen.set(false);
      }
    });
  }
}
