import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CommentInputComponent } from '../comments/comment-input/comment-input.component';
import { CommentsService } from '@/core/services/comments.service';
import { Comment } from '@/core/models/comment';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, CommentInputComponent, MatTooltipModule],
  selector: 'app-comment-reply',
  templateUrl: './comment-reply.component.html',
  styleUrls: ['./comment-reply.component.scss'],
})
export class CommentReplyComponent implements OnInit {
  @Input() id: number | string = null;
  @Output() comeback = new EventEmitter<void>();
  service = inject(CommentsService);
  comment = new BehaviorSubject<Comment>(null);
  comment$ = this.comment.asObservable();
  see: 'comments' | 'detail' = 'detail';

  constructor() {}

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.service.one(this.id).subscribe((comment: Comment) => this.comment.next(comment));
  }
}
