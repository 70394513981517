import { BehaviorSubject, Subject, debounceTime, exhaustMap, map, of, tap } from 'rxjs';
import { ListDatasourceConfig, ListItem, ListLoader } from './list.types';

export class ListDatasource<T extends ListItem = any> {
  #data = new BehaviorSubject<T[]>([]);
  #load = new Subject<boolean>();
  #options = new BehaviorSubject<any>({});
  #config: ListDatasourceConfig<T>;
  #loading = new BehaviorSubject<boolean>(true);

  get loading() {
    return this.#loading.value;
  }

  get loading$() {
    return this.#loading.asObservable();
  }

  get items() {
    return this.#data.value;
  }

  get items$() {
    return this.#data.asObservable();
  }

  get batch() {
    return this.#config.batch;
  }

  constructor(config?: ListDatasourceConfig<T> | ListLoader<T>) {
    if (!config) {
      config = () => of({ data: [], total: 0 });
    }

    if (typeof config === 'function') {
      config = { loader: config, batch: 10 };
    }

    this.#config = config;
    this.#load
      .pipe(
        tap(() => this.#loading.next(true)),
        exhaustMap((r) => this.request(r)),
      )
      .subscribe(() => this.#loading.next(false));
    this.#options.pipe(debounceTime(500)).subscribe(() => this.load(true));
  }

  private request(reset = false) {
    const data = reset ? [] : this.items;
    const args = {
      options: this.#options.value ?? {},
      skip: reset ? 0 : this.items.length,
      limit: this.#config.batch,
    };

    if (reset) {
      this.#data.next([]);
    }

    return this.#config.loader(args).pipe(
      map((resp) => data.concat(resp.data)),
      tap((data) => this.#data.next(data)),
    );
  }

  setOption(key: string, value: any) {
    this.#options.next({ ...this.#options.value, [key]: value });
  }

  getOption<T = any>(key: string): T {
    return this.#options.value[key];
  }

  load(reset = false) {
    this.#load.next(reset);
  }
}
