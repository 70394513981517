{
  "name": "@alterhome/kairosapp",
  "version": "2.2.3",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start:dev": "ng serve --configuration development",
    "start:prod": "ng serve --configuration production",
    "build": "ng build",
    "build:dev": "ng build --configuration development --stats-json true --source-map true",
    "build:prod": "ng build --configuration production",
    "test": "ng test",
    "e2e": "ng e2e",
    "lint": "ng lint",
    "analyze": "source-map-explorer dist/dev/*.js",
    "analyze:prod": "source-map-explorer dist/prod/*.js",
    "stats": "webpack-bundle-analyzer dist/dev/stats.json",
    "stats:prod": "webpack-bundle-analyzer dist/prod/stats.json"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.13",
    "@angular/cdk": "^18.2.14",
    "@angular/common": "^18.2.13",
    "@angular/compiler": "^18.2.13",
    "@angular/core": "^18.2.13",
    "@angular/forms": "^18.2.13",
    "@angular/google-maps": "^18.2.14",
    "@angular/material": "^18.2.14",
    "@angular/platform-browser": "^18.2.13",
    "@angular/platform-browser-dynamic": "^18.2.13",
    "@angular/router": "^18.2.13",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@popperjs/core": "^2.11.8",
    "@schematics/angular": "^16.1.7",
    "@types/google.maps": "^3.53.5",
    "@worktile/gantt": "^15.1.4",
    "angular-calendar": "^0.31.0",
    "animate.css": "^4.1.1",
    "apexcharts": "^3.41.1",
    "date-fns": "^2.30.0",
    "html2canvas": "^1.4.1",
    "localforage": "^1.10.0",
    "ng-apexcharts": "^1.8.0",
    "ngx-colors": "^3.5.0",
    "ngx-material-timepicker": "^13.1.1",
    "ngx-quill": "^22.1.2",
    "ngx-toastr": "^18.0.0",
    "normalize.css": "^8.0.1",
    "quill": "^1.3.7",
    "quill-magic-url": "^4.2.0",
    "quill-mention": "^4.1.0",
    "remixicon": "^3.7.0",
    "rxjs": "~7.8.0",
    "swiper": "^8.4.6",
    "tslib": "^2.3.0",
    "zone.js": "^0.14.4"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular-eslint/builder": "18.4.0",
    "@angular-eslint/eslint-plugin": "18.4.0",
    "@angular-eslint/eslint-plugin-template": "18.4.0",
    "@angular-eslint/schematics": "18.4.0",
    "@angular-eslint/template-parser": "18.4.0",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.13",
    "@types/jasmine": "^4.3.5",
    "@types/node": "^22.9.0",
    "@typescript-eslint/eslint-plugin": "7.11.0",
    "@typescript-eslint/parser": "7.11.0",
    "autoprefixer": "^10.4.16",
    "daisyui": "^4.4.10",
    "eslint": "8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.2.1",
    "jasmine-core": "~4.6.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.31",
    "prettier": "^3.3.3",
    "prettier-eslint": "^16.3.0",
    "source-map-explorer": "^2.5.3",
    "tailwindcss": "^3.4.14",
    "tailwindcss-animated": "^1.1.2",
    "typescript": "~5.5.4",
    "webpack-bundle-analyzer": "^4.10.1"
  }
}