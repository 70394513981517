import { environment } from '@environments/environment';

export const INTEGRATION_PROVIDERS = [
  {
    code: 'avantio',
    name: 'Avantio',
    color: '#f15f41',
    image: `${environment.apiUrl}/images/providers/avantio.svg`,
    fields: [
      { key: 'username', title: 'Usuario' },
      { key: 'password', title: 'Contraseña', secrect: true },
      { key: 'partner_code', title: 'Codigo de Partner', optional: true }
    ]
  },
  {
    code: 'zenvia',
    name: 'Zenvia',
    color: '#263238',
    image: `${environment.apiUrl}/images/providers/zenvia.svg`,
    fields: [{ key: 'key', title: 'Clave API' }]
  },
  {
    code: 'akiles',
    name: 'Akiles',
    color: '#fbe775',
    image: `${environment.apiUrl}/images/providers/akiles.svg`,
    fields: [{ key: 'key', title: 'Clave API' }]
  }
];
