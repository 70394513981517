import { AppService } from '@/core/services/app.service';
import { Ticket } from '@/operations/models/ticket';
import { TicketsOptions } from '@/operations/models/tickets-options';
import { TicketsService } from '@/operations/services/tickets.service';
import { TicketCardComponent } from '@/shared/components/ticket-card/ticket-card.component';
import { ListComponent } from '@/ux/components/list/list.component';
import { ListDatasource } from '@/ux/components/list/list.datasource';
import { ListResult } from '@/ux/components/list/list.result';
import { ListLoaderArgs } from '@/ux/components/list/list.types';
import { UXModule } from '@/ux/ux.module';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, inject, input, output } from '@angular/core';
import { map } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-tickets-list',
  templateUrl: './tickets-list.component.html',
  styleUrls: ['./tickets-list.component.scss'],
  imports: [UXModule, TicketCardComponent, ListComponent],
})
export class TicketsListComponent {
  ref = inject(DialogRef);
  data = inject(DIALOG_DATA);
  accommodationId = input<any>(this.data.accommodationId);
  bookingId = input<any>(this.data.bookingId);
  service = inject(TicketsService);
  paramsEmit = output<any>({ alias: 'params' });
  app = inject(AppService);
  datasource = new ListDatasource();
  public automatic: boolean = false;

  constructor() {
    this.app.on('page:load').subscribe(() => this.datasource.load(true));
    this.datasource = new ListDatasource<Ticket>((params: ListLoaderArgs) => {
      const { skip, limit, options } = params;
      const { search } = options;

      const optionsT = new TicketsOptions({
        skip,
        limit,
        mode: 'lg',
        date: null,
        search,
      });

      if (this.bookingId()) optionsT.bookings = [this.bookingId()];
      if (this.accommodationId()) optionsT.accommodations = [this.accommodationId()];

      return this.service
        .list(optionsT)
        .pipe(map((data) => <ListResult>{ data, total: data.total }));
    });
  }
}
