import { AppService } from '@/core/services/app.service';
import { KeyvalueInputComponent } from '@/ux/components/keyvalue-input/keyvalue-input.component';
import { Injectable, inject } from '@angular/core';
import { DialogService } from '@/ux/services/dialog';
import { endOfMonth, startOfMonth, subMonths } from 'date-fns';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { ResourcesService } from 'src/app/legacy/services/resources.service';
import { UXService } from 'src/app/ux/services/ux.service';
import { AccommodationFormComponent } from '../components/accommodation-form/accommodation-form.component';
import { AccommodationHostComponent } from '../components/accommodation-host/accommodation-host.component';
import { AccommodationInstructionsComponent } from '../components/accommodation-instructions/accommodation-instructions.component';
import { AccommodationLinksComponent } from '../components/accommodation-links/accommodation-links.component';
import { ExpensesComponent } from '../components/expenses/expenses.component';
import { Accommodation } from '../models/accommodation';
import { AccommodationsService } from './accommodations.service';
import { FinanceService } from './finance.service';
import { Dialog } from '@angular/cdk/dialog';
import { TicketsListComponent } from '@/shared/components/tickets-list/tickets-list.component';

@Injectable({ providedIn: 'root' })
export class AccommodationsUIService {
  #app = inject(AppService);
  finance = inject(FinanceService);
  ux = inject(UXService);
  dialog = inject(DialogService);
  #dialog = inject(Dialog);
  resources = inject(ResourcesService);
  service = inject(AccommodationsService);

  form(data?: Partial<Accommodation>) {
    const inputs = { data };
    const ref = this.dialog.open(AccommodationFormComponent, { inputs });

    return new Observable((observer) => {
      ref.events.subscribe(({ type, data: body }) => {
        if (type === 'beforeclose') observer.complete();
        else if (type === 'custom') {
          const obs = data?.id ? this.service.update(data?.id, body) : this.service.store(body);
          obs.subscribe((item) => {
            observer.next(item);
            if (item?.id) ref.close();
          });
        }
      });
    });
  }

  editTrick(accommodation: Accommodation) {
    return this.ux
      .quickedit({
        type: 'textarea',
        title: 'Tricks',
        value: accommodation.tricks,
        placeholder: 'Tricks',
      })
      .pipe(
        filter((value) => value && value !== accommodation.tricks),
        mergeMap((value) => this.service.update(accommodation.id, { tricks: value })),
      );
  }

  editPack(accommodation: Accommodation) {
    return this.resources.lite('/inventory/packs').pipe(
      mergeMap((items) =>
        this.ux.quickedit({
          type: 'select',
          title: 'Pack',
          options: items.map((item) => ({ label: item.name, value: item.id })),
          value: accommodation.pack_id,
        }),
      ),
      filter((pack_id) => pack_id && pack_id !== accommodation.pack_id),
      mergeMap((pack_id) => this.service.update(accommodation.id, { pack_id })),
    );
  }

  goMap(accommodation: Accommodation) {
    const { lat, lng } = accommodation?.coords;
    const url = `https://www.google.com/maps?q=${lat},${lng}`;

    if (lat && lng) window.open(url, '_blank');
    else this.ux.notifier.error('No se ha definido la ubicación');
  }

  settlement(accommodation: Accommodation) {
    const last = subMonths(new Date(), 1);

    return this.ux
      .quickedit({
        type: 'daterange',
        title: 'Generar liquidación',
        value: [startOfMonth(last), endOfMonth(last)],
        placeholder: 'Rango de fecha',
      })
      .pipe(
        filter((value) => value),
        mergeMap((value) => this.finance.settlement(accommodation.id, value, 'pdf')),
      );
  }

  invoice(accommodation: Accommodation) {
    const last = subMonths(new Date(), 1);

    return this.ux
      .quickedit({
        type: 'daterange',
        title: 'Generar Factura',
        value: [startOfMonth(last), endOfMonth(last)],
        placeholder: 'Rango de fecha',
      })
      .pipe(
        filter((value) => value),
        mergeMap((value) => this.finance.invoice(accommodation.id, value, 'pdf')),
      );
  }

  config(accommodation: Accommodation) {
    const configs = accommodation?.configs || {};
    const schema = {
      booking_hotel_id: { type: 'string', label: 'Id de hotel en Booking.com' },
      short_commission: { type: 'number', label: 'Comisión corta' },
      long_commission: { type: 'number', label: 'Comisión larga' },
      // irpf: { type: 'number', label: 'IRPF' },
      settlement_type: {
        type: 'select',
        label: 'Tipo de liquidación',
        options: [
          { label: 'Normal', value: 'default' },
          { label: 'Alpha', value: 'alpha' },
        ],
      },
    };
    const inputs = { value: configs, title: 'Configuraciones', schema };

    return this.ux.dialog.open(KeyvalueInputComponent, { inputs }).events.pipe(
      filter((e) => e.type === 'beforeclose' && e.data),
      mergeMap(({ data: configs }) => this.service.update(accommodation.id, { configs })),
      tap(() => this.#app.emit('page:load')),
    );
  }

  assign(accommodation: Accommodation) {
    const inputs = { data: accommodation };
    this.dialog.open(AccommodationHostComponent, { inputs });
  }

  links(accommodation: Accommodation) {
    const inputs = { accommodationId: accommodation.id };
    this.dialog.open(AccommodationLinksComponent, { inputs });
  }

  expenses(accommodationId: number, ticketId?: number) {
    const inputs = { accommodationId, ticketId };
    this.dialog.open(ExpensesComponent, { inputs });
  }

  instructions(id: number | string, data?: any) {
    this.dialog
      .open(AccommodationInstructionsComponent, { inputs: { data } })
      .events.pipe(
        filter((e) => e.type === 'beforeclose' && e.data),
        map((e) => e.data),
        mergeMap((instructions) => this.service.update(id, { instructions })),
      )
      .subscribe();
  }

  tickets(accommodation: Accommodation) {
    this.#dialog.open(TicketsListComponent, {
      data: { accommodationId: accommodation.id },
    });
  }
}
