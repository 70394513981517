import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { UXService } from 'src/app/ux/services/ux.service';
import { Accommodation } from '../models/accommodation';
import { AccommodationEvent } from '../models/accommodation-event';

@Injectable({ providedIn: 'root' })
export class AccommodationsService {
  ux = inject(UXService);
  http = inject(HttpClient);

  store(data: Partial<Accommodation>) {
    return this.http.post<any>(`//api/pms/accommodations`, data).pipe(
      tap({
        next: () => this.ux.notifier.success('¡Alojamiento creado!'),
        error: () => this.ux.notifier.error('¡Error al crear alojamiento!'),
      }),
    );
  }

  update(id: number | string, data: Partial<Accommodation>) {
    return this.http.put<any>(`//api/pms/accommodations/${id}`, data).pipe(
      tap({
        next: () => this.ux.notifier.success('¡Alojamiento actualizado!'),
        error: () => this.ux.notifier.error('¡Error al actualizar alojamiento!'),
      }),
    );
  }

  list(lite = false, params?: any) {
    if (lite) return this.http.get<any>(`//api/pms/accommodations/lite`, { params });
    return this.http.get<any>(`//api/pms/accommodations`, { params, observe: 'response' }).pipe(
      map((r) => {
        const data = r?.body || [];
        data.total = +r?.headers?.get('x-total');
        return data;
      }),
    );
  }

  one(id: number | string) {
    return this.http.get<any>(`//api/pms/accommodations/${id}`);
  }

  assign(data: any) {
    if (data.id) return this.http.put<any>(`//api/accommodations/${data.id}/assign`, data);
  }

  status(id: number | string, data: any) {
    if (id) return this.http.put<any>(`//api/pms/accommodations/${id}/status`, data);
  }

  occupation(id: number | string, params = {}) {
    const mapper = (item: any) => AccommodationEvent.fromResponse(item);

    return this.http
      .get<any>(`//api/pms/accommodations/${id}/get-occupation`, { params })
      .pipe(map((items) => items?.map(mapper)));
  }
}
