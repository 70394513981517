<section class="list-bar">
  @if (!hideSearch) {
    <div class="input flex items-center min-w-full flex-1 flex-wrap sm:min-w-0 sm:flex-initial">
      <input
        #input
        name="filter"
        class="bg-transparent flex-1 min-w-0"
        placeholder="Filtrar..."
        (input)="datasource.setOption('search', input.value)"
      />
      <i class="ri-search-2-line"></i>
    </div>
  }
  <button class="btn btn-info text-white order-9" (click)="datasource.load(true)">
    <i class="ri-refresh-line"></i>
  </button>
  <ng-content select="[bar]"></ng-content>
  @if (!hideMenu) {
    <button
      class="btn bg-base-200 order-last"
      [cdkMenuTriggerFor]="menu"
      (click)="$event.preventDefault(); $event.stopImmediatePropagation()"
    >
      <i class="ri-more-2-line"></i>
    </button>
    <ng-template #menu>
      <div cdkMenu class="dropdown-content z-[1] p-2 shadow !rounded-lg w-52">
        <ng-content select="[menu]"></ng-content>
      </div>
    </ng-template>
  }
</section>
<section #container class="list-items">
  @for (item of datasource.items; track item.id; let index = $index) {
    <div #item class="item" [style.--index]="index % datasource.batch">
      <ng-container
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{ item, index, instance: this }"
      ></ng-container>
    </div>
  }
  @if (datasource.loading) {
    <app-loading></app-loading>
  }
  @if (datasource.items.length === 0 && !datasource.loading) {
    <div class="p-4">
      <span class="text-neutral text-opacity-60"> Nada para mostrar </span>
    </div>
  }
</section>
