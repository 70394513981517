import { Component, Input, Optional } from '@angular/core';
import { DialogRef } from '@/ux/services/dialog';
import { Option } from '../../directives/option.directive';

@Component({
  selector: 'app-quickedit',
  templateUrl: './quickedit.component.html',
  styleUrls: ['./quickedit.component.scss']
})
export class QuickeditComponent {
  @Input() title?: string;
  @Input() value?: any;
  @Input() type?: string;
  @Input() placeholder?: string;
  @Input() options?: Partial<Option>[];

  constructor(@Optional() public ref?: DialogRef) {}
}
