import { UntypedFormGroup, Validators } from '@angular/forms';
import { INTEGRATION_PROVIDERS } from '../constants/integration-providers.constants';
import { IntegrationProvider } from './integration-provider';

export class Integration {
  id: number;
  name: string;
  provider: string;
  values?: any;

  constructor(obj?: Partial<Integration>) {
    Object.assign(this, obj);
  }

  providerData() {
    const data = INTEGRATION_PROVIDERS.find((p) => p.code === this.provider);

    if (!data) return null;
    return new IntegrationProvider(data);
  }

  static formObject(integration?: Partial<Integration>) {
    return {
      name: [integration?.name, [Validators.required]],
      provider: [integration?.provider, [Validators.required]],
      values: new UntypedFormGroup({})
    };
  }
}
