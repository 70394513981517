import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { SharedModule } from '../shared/shared.module';
import { AuthComponent } from './auth.component';
import { UpdatemeFormComponent } from './components/updateme-form/updateme-form.component';
import { LoginComponent } from './pages/login/login.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { PasswordFormComponent } from './components/password-form/password-form.component';
import { NotificationsConfigComponent } from './components/notifications-config/notifications-config.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';

const STANDALONE: any[] = [
  PasswordFormComponent,
  ProfileComponent,
  NotificationsConfigComponent,
  ForgotPasswordComponent,
];

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'forgot', component: ForgotPasswordComponent },
      { path: 'login', component: LoginComponent },
      { path: 'changepassword', component: ChangePasswordComponent },
      { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
    ],
  },
];

@NgModule({
  imports: [...STANDALONE, SharedModule, RouterModule.forChild(routes)],
  declarations: [AuthComponent, UpdatemeFormComponent],
  exports: [...STANDALONE],
})
export class AuthModule {}
