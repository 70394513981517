import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AppService } from '@core/services/app.service';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { fix } from 'src/app/core/helpers/object-helpers';
import { UXService } from 'src/app/ux/services/ux.service';
import { Ticket } from '../models/ticket';
import { TicketsOptions } from '../models/tickets-options';
import { CalendarEvents } from '../pages/calendar/calendar.data';
import { TicketEvent } from '../pages/calendar/ticket-event';

export type TicketInput = Partial<Ticket> & {
  date_init?: string;
  date_finish?: string;
};

@Injectable({ providedIn: 'root' })
export class TicketsService {
  #http = inject(HttpClient);
  #ux = inject(UXService);
  #app = inject(AppService);

  one(id: number | string, force?: boolean) {
    const cache = `tickets.${id}:320${force ? ':reload' : null}`;
    const headers = fix({ 'app-cache': cache });

    return this.#http
      .get(`//ops/tickets/${id}`, { headers })
      .pipe(map((response) => new Ticket(response)));
  }

  list(options: TicketsOptions, cache?: string) {
    const params = options.toRequest();
    const headers = fix({ 'app-cache': cache });

    return this.#http
      .get<any>(`//ops/tickets`, {
        params,
        headers,
        observe: 'response',
      })
      .pipe(
        map((r) => {
          const data = r?.body || [];
          data.total = +r.headers.get('x-total');
          return data;
        }),
      );
  }

  day(options: TicketsOptions) {
    const params = options.toRequest();

    return this.#http.get<any>('//ops/day', { params });
  }

  events(options: TicketsOptions) {
    options = options.clone();
    options.range = [startOfMonth(options.date), endOfMonth(options.date)];
    options.date = undefined;
    const strRange = options.range.map((d) => format(d, 'yyyyMMdd')).join('-');
    const cache = `events.${strRange}:120`;
    const mapper = (items: any[]) => {
      const events = items?.map((t) => TicketEvent.fromResponse(t));
      return new CalendarEvents(...events);
    };

    return this.list(options, cache).pipe(map(mapper));
  }

  update(id: number | string, input: TicketInput) {
    return this.#http.put(`//api/tickets/${id}`, input).pipe(
      tap({
        next: () => this.#ux.notifier.success('¡Ticket actualizado!'),
        error: () => this.#ux.notifier.error('¡Error al Actualizar Ticket!'),
      }),
      tap((result) => this.#app.emit(`ticket.updated.${id}`, { id, input, result })),
    );
  }

  create(input: TicketInput) {
    return this.#http.post(`//api/tickets`, input).pipe(
      tap({
        next: () => this.#ux.notifier.success('¡Ticket creado!'),
        error: () => this.#ux.notifier.error('¡Error al Crear Ticket!'),
      }),
    );
  }

  updateOrCreate(input: TicketInput) {
    return input?.id ? this.update(input.id, input) : this.create(input);
  }

  assign(ticketId: number | string, userId: number | string, force = false) {
    const body = {
      assigned_id: userId,
      reassign: force,
    };

    return this.#http.put(`//api/tickets/${ticketId}/assign`, body).pipe(
      tap({
        next: () => this.#ux.notifier.success('Ticket asignado correctamente.'),
        error: () => this.#ux.notifier.error('Error al asignar el ticket.'),
      }),
    );
  }

  approve(ticketId: number | string, approve = true) {
    const unapprove = !approve;
    const realized = approve ? 'aprobado' : 'desaprobado';

    return this.#http.put(`//api/tickets/${ticketId}/approve`, { unapprove }).pipe(
      tap({
        next: () => this.#ux.notifier.success(`¡Ticket ${realized}!`),
        error: () => this.#ux.notifier.error('Error al aprobar el ticket.'),
      }),
    );
  }

  spreadStatus(ticketId: number | string) {
    const endpoint = `//ops/tickets/${ticketId}/spread-status`;
    const msg = '¿Está seguro de realizar esta operación?';

    return this.#ux.confirm(msg).pipe(
      tap(console.log),
      filter((confirm) => !!confirm),
      mergeMap(() => this.#http.put(endpoint, {})),
      tap({
        next: () => this.#ux.notifier.success('¡Estado del ticket propagado!'),
        error: () => this.#ux.notifier.error('¡Error al propagar el estado del ticket!'),
      }),
    );
  }
}
