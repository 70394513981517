import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { endOfDay, format, startOfDay } from 'date-fns';
import { fix, secureStr } from 'src/app/core/helpers/object-helpers';

export class TicketsOptions {
  date: Date = new Date();
  range: Date[];
  status?: string[];
  actions?: (number | string)[];
  assigned?: (number | string)[];
  bookings?: (number | string)[];
  accommodations?: (number | string)[];
  users?: (number | string)[];
  flags?: string[];
  order?: { column: string; direction?: string };
  search?: string;
  mine?: boolean;
  mode?: 'lg' | 'sm';
  skip?: number;
  limit?: number;

  get empty() {
    return (
      !this.status?.length &&
      !this.actions?.length &&
      !this.bookings?.length &&
      !this.accommodations?.length &&
      !this.users?.length &&
      !this.flags?.length
    );
  }

  constructor(obj?: Partial<TicketsOptions>) {
    Object.assign(this, obj ?? {});

    if (typeof obj?.date == 'string' || typeof obj?.date == 'number') {
      obj.date = new Date(obj.date);
    }

    if (obj.date) this.date = this.isValidDate(obj?.date) ? obj?.date : new Date();
  }

  isValidDate(d?: Date) {
    return d && d instanceof Date && !isNaN(+d);
  }

  toRequest() {
    const date_range = this.range
      ? [
          format(startOfDay(this.range[0]), 'yyyy-MM-dd HH:mm:ss'),
          format(endOfDay(this.range[1]), 'yyyy-MM-dd HH:mm:ss'),
        ]
      : undefined;

    return fix({
      date: this.isValidDate(this.date) ? format(startOfDay(this.date), 'yyyy-MM-dd') : undefined,
      date_range: secureStr(date_range),
      status: secureStr(this.status),
      actions: secureStr(this.actions),
      assigned: secureStr(this.assigned),
      bookings: secureStr(this.bookings),
      accommodations: secureStr(this.accommodations),
      users: secureStr(this.users),
      order: secureStr(this.order),
      flags: secureStr(this.flags),
      search: this.search || undefined,
      mine: this.mine || undefined,
      mode: this.mode || undefined,
      skip: this.skip || undefined,
      limit: this.limit || undefined,
    });
  }

  toString() {
    return JSON.stringify(this.toRequest());
  }

  clone() {
    return new TicketsOptions(structuredClone(this));
  }

  static fromForm(values: any): TicketsOptions {
    if (!values?.order?.column) values.order = undefined;

    return new TicketsOptions({
      date: values.date,
      actions: values.actions,
      status: values.status ? [values.status] : undefined,
      order: values.order,
      accommodations: values.accommodation_id ? [values.accommodation_id] : undefined,
      users: values.user_id ? [values.user_id] : undefined,
      flags: Object.entries(values.flags)
        .filter(([, v]) => v)
        .map(([f]) => f),
    });
  }

  static form(values?: TicketsOptions): UntypedFormGroup {
    const { accommodations, users, status, order, flags } = values;

    const form = new UntypedFormGroup({
      date: new UntypedFormControl(format(values?.date ?? new Date(), 'yyyy-MM-dd')),
      status: new UntypedFormControl(status ? status[0] : undefined),
      accommodation_id: new UntypedFormControl(accommodations ? accommodations[0] : undefined),
      user_id: new UntypedFormControl(users ? users[0] : undefined),
      actions: new UntypedFormControl(values?.actions),
      order: new UntypedFormGroup({
        column: new UntypedFormControl(order?.column || 'date'),
        direction: new UntypedFormControl(order?.direction || 'desc'),
      }),
      flags: new UntypedFormGroup({
        resolved: new UntypedFormControl(flags?.includes('resolved') ?? false),
        canceled: new UntypedFormControl(flags?.includes('canceled') ?? false),
      }),
    });

    return form;
  }
}
