import { UsersService } from '@admin/services/users.service';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AccommodationsService } from '@pms/services/accommodations.service';
import localforage from 'localforage';
import { Observable, forkJoin, from, map, mergeMap, of } from 'rxjs';
import { ResourcesService } from 'src/app/legacy/services/resources.service';
import { OnAppInit } from '../interfaces/init.interface';
import { AppService } from './app.service';
import { AuthService } from './auth.service';
import { IntegrationsService } from '@/admin/services/integrations.service';

@Injectable({ providedIn: 'root' })
export class ConfigService implements OnAppInit {
  app = inject(AppService);
  auth = inject(AuthService);
  users = inject(UsersService);
  resources = inject(ResourcesService);
  accommodations = inject(AccommodationsService);
  integrations = inject(IntegrationsService);
  destroyRef = inject(DestroyRef);

  autoLoaded = {
    users: this.users.list({ mode: 'sm' }),
    actions: this.resources.lite('/actions'),
    accommodations: this.accommodations.list(),
    integrations: this.integrations.list()
  };

  ngOnAppInit() {
    this.app
      .on('app:login|page:load')
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        mergeMap(() => this.autoload())
      )
      .subscribe();

    this.app
      .on('app:logout')
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        mergeMap(() => this.clearAutoload())
      )
      .subscribe();

    return of(null);
  }

  autoload() {
    return forkJoin(this.autoLoaded).pipe(
      mergeMap((values) => {
        const obs = Object.entries(values).map(([k, v]) => this.set(k, v));
        return forkJoin(obs).pipe(map(() => values));
      })
    );
  }

  set(key: string, value: any = undefined) {
    let promise: Promise<any>;
    if (value === undefined || value === null) promise = localforage.removeItem(`config:${key}`);
    else promise = localforage.setItem(`config:${key}`, value);

    return from(promise);
  }

  get<T>(key: string): Observable<T> {
    const promise = localforage.getItem(`config:${key}`);

    return from(promise as Promise<T>);
  }

  clearAutoload() {
    const keys = Object.keys(this.autoLoaded);
    const obs = keys.map((key) => this.set(`config:${key}`));

    return forkJoin(obs);
  }
}
