import { Component, Input, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogRef } from '@/ux/services/dialog';
import { UsersService } from 'src/app/admin/services/users.service';
import { fix } from 'src/app/core/helpers/object-helpers';
import { AccommodationsService } from '../../services/accommodations.service';

@Component({
  selector: 'app-accommodation-host',
  templateUrl: './accommodation-host.component.html',
  styleUrls: ['./accommodation-host.component.scss']
})
export class AccommodationHostComponent implements OnInit {
  form: UntypedFormGroup;

  @Input()
  public data!: any;
  assignments?: any;

  constructor(
    private fb: UntypedFormBuilder,
    private usersService: UsersService,
    private accommodationService: AccommodationsService,
    @Optional() public ref?: DialogRef
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      host_id: [this.data?.host_id, [Validators.required]]
    });
    this.usersService.hosts().subscribe((users) => (this.assignments = users));
  }

  submit() {
    if (this.form.valid) {
      let obj = this.form?.value;
      if (this.data) obj = fix({ ...obj, id: this.data.id });
      this.accommodationService
        .assign(obj)
        .subscribe(() => this.ref.close(obj));
    }
  }
}
