@if(form){
<form [formGroup]="form" autocomplete="off" (keydown.enter)="$event.preventDefault()">
  @if(data()?.id){
  <h4 class="form__title">Actualizar tenant</h4>
  }@else {
  <h4 class="form__title">Crear nuevo tenant</h4>
  }
  <div class="form">
    <div class="form__control image" (click)="image.click()" (keyup.enter)="image.click()" tabindex="0">
      <img alt="Imagen de perfil" class="rounded-full w-full h-full object-cover"
        onerror="this.src='/assets/images/tenant.jpg';this.onerror=null;" [src]="preview" />
      <input #image type="file" hidden (change)="updateImage($event.target)" />
      <i class="ri-camera-2-line rounded-full"></i>
    </div>
    <minput label="Subdominio" formControlName="id" class="w-full"> </minput>
    <div>
      <minput label="Nombre" formControlName="name" />
      <ng-container class="form__control" formGroupName="customization">
        <minput label="Eslogan" type="text" formControlName="slogan" />
        <ngx-colors ngx-colors-trigger formControlName="color" class="mt-auto"></ngx-colors>
      </ng-container>
    </div>
    @if(host) {
      <div class="form__control">
        <span>Host: {{ host.name }} {{ host.surname }} </span>
      </div>
    }
    <div>
      @if(!data()?.id){
      <minput label="Demo" formControlName="demo" type="switch"> </minput>
      }
    </div>
    <div class="form__actions">
      <app-button class="btn-success text-base-100" matTooltip="Enviar" [disabled]="form.invalid" (click)="submit()">
        <i class="ri-save-2-fill text-lg"></i>
      </app-button>
    </div>
  </div>
</form>
}