import { Injectable } from '@angular/core';
import { addSeconds } from 'date-fns';
import localforage from 'localforage';

interface CacheRecord<T = any> {
  value: T;
  expire: number;
}

@Injectable({ providedIn: 'root' })
export class CacheService {
  #values: Record<string, CacheRecord> = {};
  #timeout: any;

  constructor() {
    this.load();
  }

  get(key: string) {
    const result = this.#values?.[key] ?? null;

    if (result?.expire < Date.now()) {
      delete this.#values[key];
      return null;
    }

    return result?.value ?? null;
  }

  set(key: string, value: any, time = 320) {
    this.#values[key] = { value, expire: +addSeconds(Date.now(), time) };
    this.commit();
  }

  async clear() {
    this.#values = {};
    await localforage.removeItem('cache');
  }

  async load() {
    this.#values = await localforage.getItem('cache');
    if (!this.#values) this.#values = {};
  }

  async commit() {
    clearTimeout(this.#timeout);
    this.#timeout = setTimeout(() => {
      localforage.setItem('cache', this.#values).then();
    }, 2000);
  }
}
