import { IntegrationProvider } from '@/admin/models/integration-provider';
import { Component, HostBinding, OnInit, inject, input, output } from '@angular/core';
import { filter, mergeMap } from 'rxjs';
import { Colors, autoTextColor } from 'src/app/core/helpers/colors';
import { UXService } from 'src/app/ux/services/ux.service';
import { Integration } from '../../models/integration';
import { IntegrationsService } from '../../services/integrations.service';
import { CanDirective } from '@/shared/directives/can.directive';

@Component({
  standalone: true,
  selector: 'app-integration-card',
  templateUrl: './integration-card.component.html',
  styleUrls: ['./integration-card.component.scss'],
  imports: [CanDirective],
})
export class IntegrationCardComponent implements OnInit {
  service = inject(IntegrationsService);
  ux = inject(UXService);
  item = input<Integration>(null);
  changedEmitter = output({ alias: 'changed' });
  providerData: IntegrationProvider;

  ngOnInit(): void {
    this.providerData = this.item().providerData();
  }

  remove() {
    if (!this.item().id) return;
    const msg = `
      Esta operación puede dañar la integridad de los datos.
      <br/> <br/>
      ¿Está seguro de eliminar la integración <b>'${this.item().name}'</b>?
    `;
    this.ux
      .confirmUpdateOrDelete(msg)
      .pipe(
        filter((confirm) => !!confirm),
        mergeMap(() => this.service.delete(this.item().id)),
      )
      .subscribe(() => this.changedEmitter.emit());
  }

  @HostBinding('style.backgroundColor')
  get backgroundColor() {
    return `${this.providerData.color || Colors.neutral200}`;
  }

  @HostBinding('style.color')
  get color() {
    return autoTextColor(this.providerData.color || Colors.neutral200);
  }
}
