import {
  HttpInterceptorFn,
  HttpRequest,
  HttpHandlerFn,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { inject } from '@angular/core';
import { AppService } from '../services/app.service';
import { ErrorHandlerService } from '../services/error-handler.service';
import { environment } from 'src/environments/environment';
import { mergeHeaders } from '../helpers/http.helpers';
import { fix } from '../helpers/object-helpers';

export const apiInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const app = inject(AppService);
  const errorHandler = inject(ErrorHandlerService);

  const handleError = (error: HttpErrorResponse) => {
    errorHandler.handleError(error, 'http');
    return throwError(() => error.error);
  };

  if (request.url.startsWith('//')) {
    const url = `${environment.apiUrl}${request.url.slice(1)}`;
    const tenant =
      request.headers.get('x-tenant') ?? app.globals.deep('tenancy.tenant.id:tenancy.subdomain');

    if (tenant) {
      const select =
        request.headers.get('x-tenant-select') ?? app.globals.deep('tenancy.select.id');

      const tenancyHeaders = fix({
        'x-tenant': tenant,
        'x-tenant-select': select,
      });
      const headers = mergeHeaders(request.headers, tenancyHeaders);
      request = request.clone({ url, headers });
    }
  }

  return next(request).pipe(catchError((error) => handleError(error)));
};
