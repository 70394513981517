import { MessageAction } from '@/ux/types/message.types';
import { Component, inject, Input, OnInit } from '@angular/core';
import { DialogRef } from '@/ux/services/dialog';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  ref?: DialogRef = inject(DialogRef, { optional: true });

  @Input()
  public title!: string;

  @Input()
  public message!: string;

  @Input()
  public actions!: MessageAction[];

  ngOnInit() {
    if (!this.actions?.length)
      this.actions = [
        { label: 'Cancelar', value: false, color: 'neutral-200', close: true },
        { label: 'Ok', value: true, color: 'success', close: true }
      ];
  }

  submit(action: any) {
    this.ref?.emit(action.value);
    if (action.close) this.ref?.close();
  }
}
