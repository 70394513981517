import { Routes } from '@angular/router';
import { environment } from '@environments/environment';
import { AuthModule } from './auth/auth.module';
import { JoinmeComponent } from './core/components/joinme/joinme.component';
import { AuthGuard } from './core/guards/auth.guard';
import { GateGuard } from './core/guards/gate.guard';
import { BridgeComponent } from './core/pages/bridge/bridge.component';

export const routes: Routes = [
  { path: '', redirectTo: '/operations/mytickets', pathMatch: 'full' },
  { path: 'joinme', component: JoinmeComponent },
  { path: 'auth', loadChildren: () => AuthModule },
  {
    path: 'bridge/:to',
    component: BridgeComponent,
    canActivate: [AuthGuard, GateGuard],
    data: { sentence: 'vision+1' },
  },
  {
    path: 'legacy',
    loadChildren: () => import('./legacy/legacy.module').then((m) => m.LegacyModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'operations',
    loadChildren: () => import('./operations/operations.module').then((m) => m.OperationsModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard, GateGuard],
    data: { sentence: 'vision+1' },
  },
  {
    path: 'pms',
    loadChildren: () => import('./pms/pms.module').then((m) => m.PmsModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard, GateGuard],
    data: { sentence: 'vision+1' },
  },
  {
    path: 'system',
    loadChildren: () => import('./system/system.module').then((m) => m.SystemModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard, GateGuard],
    data: { sentence: 'vision+3' },
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.routes'),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard, GateGuard],
    data: { sentence: 'vision+3' },
  },
  {
    path: 'lab',
    loadComponent: () => import('./core/pages/lab/lab.component').then((c) => c.LabComponent),
    canActivate: [() => !environment.production],
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canLoad: [AuthGuard, GateGuard],
    canActivate: [AuthGuard, GateGuard],
    data: { sentence: 'vision+3,control+3' },
  },
  { path: '**', redirectTo: '/operations/mytickets', pathMatch: 'full' },
];
