@if (comment$ | async; as comment) {
<div class="reply-info">
  <div class="flex items-center gap-1 w-full">
    <button matTooltip="Volver" class="btn btn-icon bg-transparent shadow-none !p-2" (click)="this.comeback.emit()">
      <i class="ri-arrow-go-back-fill !text-lg !font-normal"></i>
    </button>
    <span class="text-primary">Respuestas ↴</span>
  </div>
  <div class=" flex flex-col rounded-xl bg-neutral-100 p-3 w-full">
    <div class="flex flex-row items-center w-full mb-2">
      <div class="flex gap-2">
        <div class="flex gap-1 flex-row">
          @if(!comment.isAutomatic){
          <div class="reply-info__image">
            <img class="w-full h-full object-cover" [src]="comment.picture"
              onError="this.onerror=null;this.src='/assets/images/default-profile.jpg';" />
          </div>
          }
          <span class="flex items-center gap-1 text-sm font-medium text-primary">
            @if (comment.isAutomatic) {
            <i class="ri-robot-2-line"></i>
            Sistema
            } @else {
            {{ comment.user }}
            }
          </span>
        </div>
      </div>
      <span class="text-xs ml-auto text-soot">
        {{ comment?.createdAt | date: 'medium' }}
      </span>
    </div>
  
    <app-comment-input [readonly]="true" [attachments]="comment.attachments" [(ngModel)]="comment.content">
    </app-comment-input>
  </div>
</div>
}