import { AppService } from '@/core/services/app.service';
import { UXModule } from '@/ux/ux.module';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnChanges, OnInit, SimpleChanges, inject, input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DialogRef } from '@/ux/services/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { pathFileType } from 'src/app/core/helpers/files-helper';
import { fix } from 'src/app/core/helpers/object-helpers';
import { environment } from 'src/environments/environment';
import { SharedModule } from '../../shared.module';
import { CommentsComponent } from '../comments/comments.component';

@Component({
  standalone: true,
  selector: 'app-ticket-view',
  templateUrl: './ticket-view.component.html',
  styleUrls: ['./ticket-view.component.scss'],
  imports: [CommentsComponent, SharedModule, UXModule, CommonModule],
})
export class TicketViewComponent implements OnInit, OnChanges {
  #app = inject(AppService);
  #http = inject(HttpClient);
  #ticket = new BehaviorSubject<any>(null);
  #request = new Subject<{ force?: boolean; comments?: boolean }>();

  dialogRef?: DialogRef = inject(DialogRef, { optional: true });

  id = input.required<string | number>();
  readonly = input<boolean>(false);
  loading = signal(false);
  viewReplies = signal(false);

  ticket$ = this.#ticket.asObservable();
  see: 'comments' | 'detail' = 'detail'; // ONLY MOBILE
  around?: any;
  commentId: string | number | null = null;

  constructor() {
    this.request();
    this.ticket$
      .pipe(
        takeUntilDestroyed(),
        tap((t) => this.loadAround(t)),
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.id) this.load(true);
  }

  ngOnInit() {
    this.load();
  }

  load(force?: boolean, comments?: boolean) {
    this.#request.next({ force, comments });
  }

  request() {
    this.#request
      .pipe(
        debounceTime(1000),
        switchMap(({ force }: any) => {
          this.loading.set(true);
          const cache = `ticket.${this.id()}:320${force ? ':reload' : null}`;
          const headers = fix({ 'app-cache': cache });

          return this.#http
            .get(`//api/tickets/${this.id()}`, { headers })
            .pipe(map((res) => this.fromResponse(res)));
        }),
      )
      .subscribe((t) => {
        this.#ticket.next(t);
        this.loading.set(false);
      });
  }

  parseAssigned(assigned) {
    if (!assigned) return;
    const image = assigned.image;
    const globals = this.#app.globals;
    const tenant = globals.deep('tenancy.select.id:tenancy.tenant.id');

    if (image && !image.startsWith('http'))
      assigned.image = `${environment.apiUrl}${image}?tenant=${tenant}`;

    return assigned;
  }

  parseAttachments(observations: any[] = []) {
    const uri = environment.apiUrl;
    const globals = this.#app.globals;
    const tenant = globals.deep('tenancy.select.id:tenancy.tenant.id');

    return observations?.reduce((p, c) => {
      const { id, name, file, files } = c;
      if (file) {
        const type = pathFileType(file);
        p.push({ id, name, src: `${uri}${file}?tenant=${tenant}`, type });
      } else if (files) {
        const attachs = files?.map((p) => ({
          id,
          name,
          src: `${uri}${p}?tenant=${tenant}`,
          type: pathFileType(p),
        }));
        p = p.concat(attachs || []);
      }
      return p;
    }, []);
  }

  fromResponse(ticket: any) {
    ticket.assigned = this.parseAssigned(ticket?.assigned);
    ticket.subscribed = this.subscribed(ticket);
    ticket.attachments = this.parseAttachments(ticket?.observations);
    const parentTags = ticket.parent_tags || [];
    const allTags = [...(ticket.tags || []), ...parentTags];
    const uniqueTags = Array.from(new Map(allTags.map((tag) => [tag.id, tag])).values());
    ticket.tags = uniqueTags;

    return ticket;
  }

  loadAround(ticket: any) {
    const { id, action_id, target } = ticket || {};
    const { accommodation_id } = target || {};
    const params = fix({ action_id, accommodation_id });
    this.#http
      .get(`//api/tickets/${id}/move`, { params })
      .subscribe((data) => (this.around = data));
  }

  subscribed(ticket) {
    const subscribers = ticket?.subscribers || [];
    const user = this.#app.globals.deep('session.user') || {};
    return subscribers.some((s) => s?.id === user?.id);
  }

  reply(id: string | number) {
    if (id === 'hide') {
      this.commentId = null;
      this.viewReplies.set(false);
    } else {
      this.commentId = id;
      this.viewReplies.set(true);
    }
  }
}
