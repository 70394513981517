<app-bg-auth></app-bg-auth>
<section class="changepassword-content">
  <h2 class="font-semibold text-3xl text-primary self-center md:self-start">
    ¡Ingresa una nueva contraseña!
  </h2>
  <p class="text-primary self-center md:self-start">
    Después tendrás que iniciar sesión con tu nueva contraseña.
  </p>
  <form #formEl class="flex-initial" [formGroup]="form" (ngSubmit)="submit()">
    <form-control label="Nueva contraseña" name="password">
      <input class="input !bg-white/50" formControlName="password" type="password" />
    </form-control>
    <form-control label="Confirmar contraseña" name="confirm">
      <input class="input !bg-white/50" formControlName="confirm" type="password" />
    </form-control>
    <div class="form__actions">
      <app-button type="submit" class="btn-primary rounded-full w-full md:w-48 self-center" [block]="true"
        [disabled]="form.invalid">
        <span class="font-semibold">Cambiar contraseña</span>
      </app-button>
    </div>
  </form>
</section>